import * as React from 'react'
import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import Block from 'components/blocks/Block'

const ExpertiseTemplate = ({ data: { pg } }) => (
	<DefaultLayout {...pg.seo}>
		{pg.blocks?.map((block) => (
			<Block {...block} key={block?._key} />
		))}
	</DefaultLayout>
)

export default ExpertiseTemplate

export const query = graphql`
	query ExpertiseTemplate($id: String) {
		pg: sanityExpertisePg(id: { eq: $id }) {
			blocks {
				... on SanityBlockGroup {
					_key
					_type
					style
					blocks {
						...statList
					}
				}
				...altEnergyContent
				...featuresWithIcons
				...heroBlock
				...heroVideo
				...heroWithVideo
				...hvacContent
				...imageAndTextSections
				...industriesServed
				...logoListScroll
				...partners
				...projectList
				...safetyStats
				...servicesList
				...textWithBg
			}
			seo {
				title
				description
			}
		}
	}
`
